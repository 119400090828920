var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-3 pa-3",attrs:{"flat":"","outlined":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Manage User")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":"/main/admin/users/create"}},[_vm._v("Create User")])],1),_c('v-card-text',[_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"dense":""},scopedSlots:_vm._u([{key:"item.api_key",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.api_key.substring(0, 16))+"... "),_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.copy(item.api_key)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)],1)]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [(item.is_active)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_vm._e()]}},{key:"item.is_superuser",fn:function(ref){
var item = ref.item;
return [(item.is_superuser)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_vm._e()]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":"","to":{
                    name: 'main-admin-users-edit',
                    params: { id: item.id },
                  }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }